<template>
    <div>
        <card-header title="Trip Itinerary" icon="fa-calendar-alt"/>
        <card-toolbar>
            <button @click="$openCard('new-day', {tripId: trip.id}, card)"><i class="fas fa-calendar-plus mr-2"/>New Day</button>
            <template #right>
                <button class="icon-only" @click="$openCard('duplicate-trip-days', {tripId: trip.id}, card)"><i class="far fa-copy"/></button>
            </template>
        </card-toolbar>

        <card-body>
            <card-list>
                <trip-list-item :card="card" :trip="trip"/>
                <subheader-list-item title="Trip Days" icon="fas fa-calendar-alt" :badge="days.length"/>
                <day-list-item
                    :card="card"
                    :day="day"
                    v-for="day in days"
                    :active="day.active"
                    @click="$openCard('day', {dayId: day.id}, card)"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DayListItem from "../../components/DayListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {DayListItem, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                days: [],
                trip: {}
            }
        },
        methods: {
            loadDays: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/days/0/30', {force}).then(response => {
                    for (let i = 0; i < response.data.days.length; i++) {
                        response.data.days[i].active = false;
                    }
                    this.days = response.data.days;
                    this.setActive();
                    this.$emit('loading', false);
                });
            },
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                });
            },
            setActive: function () {
                let check = (this.child && this.child.definition.component === 'day') ? parseInt(this.child.props.dayId) : null;
                this.days.map(t => t.active = (t.id === check));
            },
            reload: function() {
                this.loadDays(true);
            }
        },
        watch: {
            'props': function() {
                this.loadDays();
            },
            'child': function () {
                this.setActive();
            }
        },
        mounted() {
            this.loadDays();
            this.loadTrip();
        }
    };
</script>

<style lang="scss" scoped>

</style>
